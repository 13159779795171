<template>
  <div>
    <div class="row mt-4">
      <v-container v-if="progress" type="image" class="loding">
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </v-container>
        <div v-else class="col-md-6 col-lg-6 col-sm-12">
        <div class="background-white mt-3">
          <div class="title">
            <div class="position">{{$t("information_technique")}}</div>
          </div>
        </div>

        <div class="center borderPointier mt-3 py-3">
        
       
            <form @submit.prevent="systemeSolaireAjouter">
              <div class="center mt-3 p-3">
                <div>
                  <b-form-radio
                    v-model="selected"
                    class="mb-3"
                    name="radios1"
                    value="A"
                  >
                  {{$t("certification")}}
                  </b-form-radio>

                  <div class="mx-3" v-if="selected === 'A'">
                    <div>
                      <b-form-radio
                        v-model="selectedNorme"
                        class="mb-3"
                        name="radios2"
                        value="C"
                      >
                      {{$t("fondée_sur_les_normes")}}
                      </b-form-radio>
                      <div class="fieldset m-3" v-if="selectedNorme === 'C'">
                        <div class="legend mb-2">
                          {{ $t("NOM_CERTIF") }}
                        </div>
                        <b-form-input
                          type="text"
                          v-model="systeme_solaire.certification"
                          :placeholder="$t('NOM_CERTIF')"
                          class="form-input custom-placeholder custom-input"
                        >
                        </b-form-input>
                      </div>
                   

                      <b-form-radio
                        v-model="selectedNorme"
                        class="mb-3"
                        name="radios2"
                        value="D"
                      > {{$t("Autres_normes")}}
                      </b-form-radio>

                      <div v-if="selectedNorme === 'D'">
                        <div class="fieldset mt-3">
                          <div class="legend mb-2">
                            {{ $t("NOM_CERTIF") }}
                          </div>
                          <b-form-input
                            type="text"
                            v-model="systeme_solaire.certification"
                            :placeholder="$t('NOM_CERTIF')"
                            class="form-input custom-placeholder custom-input"
                          >
                          </b-form-input>
                        </div>

                        <div class="fieldset my-3">
                          <div class="legend mb-2">
                            {{ $t("NORM") }}
                          </div>
                          <b-form-input
                            type="text"
                            v-model="systeme_solaire.norme"
                            :placeholder="$t('NORM')"
                            class="form-input col- custom-placeholder custom-input"
                          >
                          </b-form-input>
                        </div>

                      </div>
                    </div>
                  </div>
                  <b-form-radio
                    v-model="selected"
                  
                    name="radios1"
                    value="B"
                  >
                  {{$t("capteurs_posèdent_caractéristiques_performances")}}
                    
                  </b-form-radio>
                </div>

              </div>
              <div class="fieldset mx-4" >
                <div class="legend mb-3">
                  {{ $t("FLUIDE") }}
                </div>
                <b-form-input
                  type="text"
                  v-model="systeme_solaire.nature_fluide"
                  :placeholder="$t('FLUIDE')"
                  class="form-input custom-placeholder custom-input"
                >
                </b-form-input>
              </div> 
            </form>
       
        </div>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>
        <div v-else>
        <div class="background-white mt-3">
          <div class="title">
            <div class="position">{{$t("information_technique")}}</div>
          </div>
        </div>
        <div class="borderPointier mt-3 pt-3 px-3">
            <div class="">
              <div class="fieldset mt-4">
                <div class="legend mb-2">
                  {{ $t("Productivité") }}
                </div>
                <b-form-input
                  type="number"
                  min="0"
                  @paste="(event) => onPaste(event, 'systeme_solaire.productivite')"

                  @keydown="(event) => onKeyDownFloat(event)"
                  v-model="systeme_solaire.productivite"
                  :placeholder="$t('PRODUIT')"
                  class="form-input custom-placeholder custom-input"
                >
                </b-form-input>
              </div>

              <div class="fieldset mt-3">
                <div class="legend mb-2">
                  {{ $t("Surface_chauffe") }}
                </div>
                <b-form-input
                  type="number"
                  min="0"
                  @paste="(event) => onPaste(event, 'systeme_solaire.surface_chaufe')"
                     @keydown="(event) => onKeyDownFloat(event)"
                  v-model="systeme_solaire.surface_chaufe"
                  :placeholder="$t('Surface_chauffe')"
                  class="form-input custom-placeholder custom-input"
                >
                </b-form-input>
              </div>
              <div class="fieldset mt-3">
                <div class="legend mb-2">
                  {{ $t("Surface_hors_tout") }}
                </div>
                <b-form-input
                  type="number"
                  min="0"
                  @paste="(event) => onPaste(event, 'systeme_solaire.surface_hors_tout')"
                   @keydown="(event) => onKeyDownFloat(event)"
                  v-model="systeme_solaire.surface_hors_tout"
                  :placeholder="$t('Surface_hors_tout')"
                  class="form-input custom-placeholder custom-input"
                >
                </b-form-input>
              </div>
              <div class="fieldset mt-3">
                <div class="legend mb-2">
                  {{ $t("Capacite_stockage") }}
                </div>
                <b-form-input
                  type="number"
                  min="0"
                  
                       @paste="(event) => onPaste(event, 'systeme_solaire.capacite_stockage')"
                    @keydown="(event) => onKeyDownFloat(event)"
                  v-model="systeme_solaire.capacite_stockage"
                  :placeholder="$t('Capacite_stockage')"
                  class="form-input custom-placeholder custom-input"
                >
                </b-form-input>
              </div>
              <div class="fieldset mt-3 mb-5">
                <div class="legend mb-2">
                  {{ $t("Classe_efficacite_energetique") }}
                </div>
                <b-form-input
                  type="text"
                  v-model="systeme_solaire.classe_efficacite_energetique"
                  :placeholder="$t('Classe_efficacite_energetique')"
                  class="form-input custom-placeholder custom-input"
                >
                </b-form-input>
              </div>
            </div>
          </div>
          <!-- <div v-if="show" class="d-flex justify-content-end mt-4">
            <b-button v-if="this.visibleTabsLength=1" @click="goToPrevStep"
             class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToStep"
        class="button-suivant-style m-3"
      >
        <template v-if="isLoading">
          <div class="loading ml-2">
            <div class="spinner-border spinner-border-sm" role="status"></div>
          </div>
        </template>
       
        <template v-else>
          {{ buttonText }}
        </template>
      </b-button>
    </div> -->
        </div>
      </div>
      
    </div>
    
    <div v-if="!show" class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{$t("PREVIOUS")}}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
      {{$t("NEXT")}}
      <div class="loading ml-2" v-if="isLoading">
        <div class="spinner-border spinner-border-sm" role="status"></div>
      </div>
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
    <Snackbar ref="snackbar" />
  </div>
</template>
<script>
import Snackbar from "@/components/ui/errorSnackbar.vue";

import { mapActions, mapGetters } from "vuex";
import { handlePaste, preventExceedingLengthFloat } from '@/utils/inputUtils'; 
export default {
  components: {
    Snackbar,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
      required: false,
    },
    visibleTabsLength: {
      type: Number,
      required: true,
    },
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCompleted: false,
      isLoading:false,
      selected: "A",
      selectedNorme: "C",
      systeme_solaire: null,
      systemeSolaire: false,
    };
  },
  created() {
    if (this.getdevisMonoFiche && this.getdevisMonoFiche.data) {
      this.systeme_solaire = this.initializesystemeSolaire();
      this.setSelectedValues();
    }
  },
  computed: {
    ...mapGetters(["getdevisMonoFiche"]),
    buttonText() {
      return this.visibleTabsLength <= 1 ? this.$t("NEXT") : this.$t("CONFIRMER");
    },
  },
  watch: {
    getdevisMonoFiche: {
      handler(newDevis) {
        if (newDevis && newDevis.data) {
          this.systeme_solaire = this.initializesystemeSolaire();
          this.systemeSolaire = newDevis.data?.devis.systeme_solaire !== null;
          this.setSelectedValues();
          if (this.systemeSolaire) {
            this.isCompleted = true;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions([
      "setDevisMonoFiche",
      "editSystemeSolaire",
      "storeSystemeSolaire",
    ]),
    onKeyDownFloat(event) {
      preventExceedingLengthFloat(event);
    },
    
    onPaste(event, fieldName) {
      handlePaste.call(this, event, fieldName);
    },
  
    setSelectedValues() {
      const norme = this.systeme_solaire.norme;

      if (
        norme ===
        "Les capteurs possèdent des caractéristiques de performances et de qualité équivalentes, certifiées par un organisme accrédité dans l'Espace Économique Européen"
      ) {
        this.selected = "B";
      } else {
        this.selected = "A";
      }

      if (this.selected === "A" && norme === "ISO 9806 et NF EN 12975") {
        this.selectedNorme = "C";
      } else {
        this.selectedNorme = "D";
      }
    },
    initializesystemeSolaire() {
      const systemeSolaire =
        this.getdevisMonoFiche?.data?.devis.systeme_solaire || {};
      return {
        id: systemeSolaire.id,
        have_norme: systemeSolaire.have_norme || false,
        have_certification: systemeSolaire.have_certification || false,
        certification: systemeSolaire.certification || "",
        norme: systemeSolaire.norme || "",
        classe_efficacite_energetique:
          systemeSolaire.classe_efficacite_energetique || "",
        nature_fluide: systemeSolaire.nature_fluide || "",
        productivite: systemeSolaire.productivite || "",
        surface_chaufe: systemeSolaire.surface_chaufe || "",
        surface_hors_tout: systemeSolaire.surface_hors_tout || "",
        capacite_stockage: systemeSolaire.capacite_stockage || "",
      };
    },
    async systemeSolaireAjouter() {
      if (this.selected === "B") {
        this.systeme_solaire.have_certification = false;
        this.systeme_solaire.have_norme = true;
        this.systeme_solaire.norme =
          "Les capteurs possèdent des caractéristiques de performances et de qualité équivalentes, certifiées par un organisme accrédité dans l'Espace Économique Européen";
      } else {
        this.systeme_solaire.have_certification = true;
        this.systeme_solaire.have_norme = true;
        if (this.selectedNorme === "C") {
          this.systeme_solaire.norme = "ISO 9806 et NF EN 12975";
        }
      }

      this.setDevisMonoFiche(this.getdevisMonoFiche);
      const response = await this.storeSystemeSolaire(this.systeme_solaire);
      this.getdevisMonoFiche.data.devis.systeme_solaire_combinee_id =
        response.data.id;
      this.getdevisMonoFiche.data.devis.systeme_solaire = {
        id: response.data.id,
        nature_fluide: this.systeme_solaire.nature_fluide,
        certification: this.systeme_solaire.certification,
        norme: this.systeme_solaire.norme,
        have_norme: this.systeme_solaire.have_norme,
        have_certification: this.systeme_solaire.have_certification,
        classe_efficacite_energetique:
          this.systeme_solaire.classe_efficacite_energetique,
        productivite: this.systeme_solaire.productivite,
        surface_chaufe: this.systeme_solaire.surface_chaufe,
        surface_hors_tout: this.systeme_solaire.surface_hors_tout,
        capacite_stockage: this.systeme_solaire.capacite_stockage,
      };
      this.setDevisMonoFiche(this.getdevisMonoFiche);
      return response;
    },

    async systemeSolaireAdd() {
      if (this.systemeSolaire) {
        if (this.selected === "B") {
          this.systeme_solaire.have_certification = false;
          this.systeme_solaire.have_norme = true;

          this.systeme_solaire.certification = "";
          this.systeme_solaire.norme =
            "Les capteurs possèdent des caractéristiques de performances et de qualité équivalentes, certifiées par un organisme accrédité dans l'Espace Économique Européen";
        } else {
          this.systeme_solaire.have_certification = true;
          this.systeme_solaire.have_norme = true;
          if (this.selectedNorme === "C") {
            this.systeme_solaire.norme = "ISO 9806 et NF EN 12975";
          }
        }

        const chauffe = {
          ...this.systeme_solaire,
          id: this.getdevisMonoFiche.data.devis.systeme_solaire.id,
        };

        const reponse = await this.editSystemeSolaire(chauffe);
        this.getdevisMonoFiche.data.devis.systeme_solaire = {
          id: this.getdevisMonoFiche.data.devis.systeme_solaire.id,
          nature_fluide: this.systeme_solaire.nature_fluide,
          certification: this.systeme_solaire.certification,
          norme: this.systeme_solaire.norme,
          have_norme: this.systeme_solaire.have_norme,
          have_certification: this.systeme_solaire.have_certification,
          classe_efficacite_energetique:
            this.systeme_solaire.classe_efficacite_energetique,
          productivite: this.systeme_solaire.productivite,
          surface_chaufe: this.systeme_solaire.surface_chaufe,
          surface_hors_tout: this.systeme_solaire.surface_hors_tout,
          capacite_stockage: this.systeme_solaire.capacite_stockage,
        };

        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return reponse;
      }
      const res = await this.systemeSolaireAjouter();
      this.getdevisMonoFiche.data.devis.systeme_solaire_combinee_id =
        res.data.id;
      this.getdevisMonoFiche.data.devis.systeme_solaire = {
        id: res.data.id,
        nature_fluide: this.systeme_solaire.nature_fluide,
        certification: this.systeme_solaire.certification,
        norme: this.systeme_solaire.norme,
        have_norme: this.systeme_solaire.have_norme,
        have_certification: this.systeme_solaire.have_certification,
        classe_efficacite_energetique:
          this.systeme_solaire.classe_efficacite_energetique,
        productivite: this.systeme_solaire.productivite,
        surface_chaufe: this.systeme_solaire.surface_chaufe,
        surface_hors_tout: this.systeme_solaire.surface_hors_tout,
        capacite_stockage: this.systeme_solaire.capacite_stockage,
      };
      this.setDevisMonoFiche(this.getdevisMonoFiche);
      return res;
    },
    async goToStep() {
      this.isLoading=true;
      if(!this.systeme_solaire.nature_fluide){
        this.isLoading=false;
        this.$refs.snackbar.showSnackbar("Tous_obligatoires");
        this.isCompleted = false;
        return;
      }
      await this.systemeSolaireAdd();
      this.isLoading=false;
      this.isCompleted = true;
      if(this.visibleTabsLength<=1){
        this.$emit("change",true);
      }else {
        this.$emit("change",false);
      }
    },


    async goToNextStep() {
      this.isLoading=true;
      if(!this.systeme_solaire.nature_fluide){
        this.isLoading=false;
        this.$refs.snackbar.showSnackbar("Tous_obligatoires");
    
        return;
      }
      await this.systemeSolaireAdd();
      this.isLoading=false;
      this.$emit("changeStep", 6, true);
    },
    goToPrevStep() {
      if(this.visibleTabsLength<=1){
        this.$emit("changePrev",true);
      }else {
        this.$emit("changePrev",false);
      }
      this.$emit("changeStep", 4);
    },
  },
};
</script>
<style scoped>
.custom-placeholder::placeholder {
  font-size: 13px;
}
.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-input {
  box-shadow: none;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>

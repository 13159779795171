<template>
  <div>
    <div>
      <div>
        <div class="row my-4 mx-1 justify-content-center">
          <v-container v-if="progress" type="image" class="loding">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </v-container>
          <div v-else class="col-lg-8 col-sm-12 col-md-10 borderPointier">
            <div class="background-white">
              <div class="title">
                <div class="position">
                  {{ $t("conditions_technique") }} 
                </div>
              </div>
            </div>
            <form @submit.prevent="isolation_mursAdd" class="my-3">
              <b-form-group
                :label="$t('Types_isolants_thermiques')"
                label-for="select-type-appareil"
              >
                <b-form-select
                  v-model="type_isolant"
                  :options="typeAppareilOptions"
                  id="select-type-appareil"
                  class="custom-input"
                >
                </b-form-select>
              </b-form-group>

              <b-form-group
                :label="$t('NORM')"
                label-for="select-norme"
                v-if="type_isolant"
              >
                <b-form-select
                  v-model="norme"
                  :options="normeOptions"
                  id="select-norme"
                  class="custom-input"
                >
                </b-form-select>
              </b-form-group>
              <div class="fieldset mb-2">
                <div class="legend mb-2">
                  {{ $t("surface_isolant") }}
                </div>
                <b-form-input
                  type="number"
                  @paste="
                    (event) =>
                      onPaste(event, 'isolation_mursData.Surface_isolant')
                  "
                  @keydown="(event) => onKeyDown(event)"
                  min="0"
                  v-model="isolation_mursData.Surface_isolant"
                  :placeholder="$t('surface_isolant')"
                  class="form-input custom-placeholder custom-input"
                >
                </b-form-input>
              </div>
              <div class="fieldset mb-2">
                <div class="legend mb-2">
                  {{ $t("resistance_thermique") }}
                </div>
                <b-form-input
                  type="number"
                  min="0"
                  @paste="
                    (event) =>
                      onPaste(event, 'isolation_mursData.resistance_thermique')
                  "
                  @keydown="(event) => onKeyDown(event)"
                  v-model="isolation_mursData.resistance_thermique"
                  :placeholder="$t('resistance_thermique')"
                  class="form-input custom-placeholder custom-input"
                >
                </b-form-input>
              </div>
              <div class="fieldset mb-2">
                <div class="legend mb-2">
                  {{ $t("epaisseur") }}
                </div>
                <b-form-input
                  type="number"
                  @paste="
                    (event) => onPaste(event, 'isolation_mursData.epaisseur')
                  "
                  @keydown="(event) => onKeyDown(event)"
                  min="0"
                  v-model="isolation_mursData.epaisseur"
                  :placeholder="$t('epaisseur')"
                  class="form-input custom-placeholder custom-input"
                >
                </b-form-input>
              </div>
            </form>
       
          </div>
        </div>
     
      </div>
  
      <!-- <div v-if="show" class="d-flex justify-content-end mt-4">
            <b-button v-if="this.visibleTabsLength<=1" @click="goToPrevStep" class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToStep"
        class="button-suivant-style m-3"
      >
        <template v-if="isLoading">
          <div class="loading ml-2">
            <div class="spinner-border spinner-border-sm" role="status"></div>
          </div>
        </template>
    
        <template v-else>
          {{ buttonText }}
        </template>
      </b-button>
    </div> -->
      <div v-if="!show" class="d-flex justify-content-end mt-4">
        <b-button @click="goToPrevStep" class="button-pre-style m-3">
          {{ $t("PREVIOUS") }}
        </b-button>
        <b-button
          type="submit"
          @click="goToNextStep"
          class="button-suivant-style m-3"
        >
          {{ $t("NEXT") }}
          <div class="loading ml-2" v-if="isLoading">
            <div class="spinner-border spinner-border-sm" role="status"></div>
          </div>
          <div class="loading ml-2" v-if="isLoadingButton">
            <div class="spinner-border spinner-border-sm" role="status"></div>
          </div>
        </b-button>
        <Snackbar ref="snackbar" class="mt-5" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import domain from "@/environment";
import axios from "axios";
import Snackbar from "@/components/ui/errorSnackbar.vue";
import { handlePaste, preventExceedingLengthFloat } from "@/utils/inputUtils";
export default {
  components: {
    Snackbar
  },
  props: {
    title: {
      type: String,
      default: "",
      required: false,
    },
    visibleTabsLength: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
      required: false,
    },
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCompleted: false,
      isLoading: false,
      isolation_mursData: null,
      isolation_murs: false,
      type_isolant: null,
      norme: null,
      errorMessage: "",
      typeAppareilOptions: [
        { value: null, text: this.$t("Sélectionnez_type"), disabled: true },
        { value: "non reflechissant", text: this.$t("non_reflechissant") },
        { value: "reflechissant", text: this.$t("reflechissant") },
      ],
      normesByType: {
        reflechissant: [{ value: "NF EN 16012+A1", text: "NF EN 16012+A1" }],
        "non reflechissant": [
          { value: "NF EN 12664", text: "NF EN 12664" },
          { value: "NF EN 12667", text: "NF EN 12667" },
          { value: "NF EN 12939", text: "NF EN 12939" },
        ],
      },
    };
  },
  created() {
    if (this.getdevisMonoFiche && this.getdevisMonoFiche.data) {
      this.isolation_mursData = this.initializeisolation_murs();
    }
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
    }),
    ...mapGetters(["getdevisMonoFiche"]),
    buttonText() {
      return this.visibleTabsLength <= 1 ? this.$t("NEXT") : this.$t("CONFIRMER");
    },
    normeOptions() {
      return this.type_isolant ? this.normesByType[this.type_isolant] : [];
    },
  },

  watch: {
    getdevisMonoFiche: {
      handler(newDevis) {
        if (newDevis && newDevis.data) {
          this.isolation_mursData = this.initializeisolation_murs();
          this.isolation_murs = newDevis.data?.devis.isolation_murs !== null;
          this.type_isolant =
            newDevis.data?.devis?.isolation_murs?.type_isolant || null;
          this.norme = newDevis.data?.devis?.isolation_murs?.norme || null;
          if (this.isolation_murs) {
            this.isCompleted = true;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["setDevisMonoFiche"]),

    onKeyDown(event) {
      preventExceedingLengthFloat(event);
    },

    onPaste(event, fieldName) {
      handlePaste.call(this, event, fieldName);
    },
    async postIsolationMur(payload) {
      try {
        const response = await axios.post(domain + `/isolation_murs`, payload, {
          headers: {
            Authorization: `Bearer ` + this.token,
          },
        });

        return response.data.data;
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.errorMessage = error.response.data.message;
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          this.errorMessage = error.response.data.errors.join(", ");
        } else {
          this.errorMessage = "Vérifiez vos données.";
        }

        this.$refs.snackbar.showSnackbar(this.errorMessage);

        return null;
      }
    },

    async putIsolationMur(payload) {
      try {
        const response = await axios.put(
          domain + `/isolation_murs/${payload.id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ` + this.token,
            },
          }
        );

        return response.data.data;
      } catch (error) {
        throw new Error("Failed to post isolation combles toiture.");
      }
    },
    initializeisolation_murs() {
      const isolation_murs =
        this.getdevisMonoFiche?.data?.devis.isolation_murs || {};

      return {
        id: isolation_murs.id,
        resistance_thermique: isolation_murs.resistance_thermique || "",
        norme: isolation_murs.norme || "",
        epaisseur: isolation_murs.epaisseur || "",
        Surface_isolant: isolation_murs.Surface_isolant || "",
        type_isolant: isolation_murs.type_isolant || "",
      };
    },
    async isolationAdd() {
      this.isolation_mursData.type_isolant = this.type_isolant;
      this.isolation_mursData.norme = this.norme;
      // if (this.isolation_mursData.Surface_isolant == "") {
      //   this.isLoading = false;
      //     this.$refs.snackbar.showSnackbar("Tous_obligatoires");
      //   return;
      // }
      const response = await this.postIsolationMur(this.isolation_mursData);

      if (response !== null) {
        this.getdevisMonoFiche.data.devis.isolation_murs_id = response.id;

        this.getdevisMonoFiche.data.devis.isolation_murs = {
          id: this.getdevisMonoFiche.data.devis.isolation_murs_id,
          Surface_isolant: this.isolation_mursData.Surface_isolant,
          epaisseur: this.isolation_mursData.epaisseur,
          resistance_thermique: this.isolation_mursData.resistance_thermique,
          norme: this.isolation_mursData.norme,
          type_isolant: this.isolation_mursData.type_isolant,
        };

        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return response;
      } else {
        this.isLoading = false;

        return;
      }
    },

    async isolation_mursAdd() {
      if (this.isolation_murs) {
        this.isolation_mursData.type_isolant = this.type_isolant;
        this.isolation_mursData.norme = this.norme;
        const chauffe = {
          ...this.isolation_mursData,
          id: this.getdevisMonoFiche.data.devis.isolation_murs.id,
        };

        const reponse = await this.putIsolationMur(chauffe);
        this.getdevisMonoFiche.data.devis.isolation_murs = {
          id: this.getdevisMonoFiche.data.devis.isolation_murs.id,
          Surface_isolant: this.isolation_mursData.Surface_isolant,
          resistance_thermique: this.isolation_mursData.resistance_thermique,
          epaisseur: this.isolation_mursData.epaisseur,
          norme: this.isolation_mursData.norme,
          type_isolant: this.isolation_mursData.type_isolant,
        };

        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return reponse;
      }
      await this.isolationAdd();
    },
    async goToStep() {
      this.isLoading = true;
      if (this.isolation_mursData.Surface_isolant == "") {
        this.isLoading = false;
        this.$refs.snackbar.showSnackbar("Tous_obligatoires");
      }
      await this.isolation_mursAdd();
      if (this.errorMessage !== "") {
        this.isLoading = false;
        this.isCompleted = true;
        return;
      }
      this.isLoading = false;
      if(this.visibleTabsLength<=1){
        this.$emit("change",true);
      }else {
        this.$emit("change",false);
      }
    },
    async goToNextStep() {
      this.isLoading = true;
      if (this.isolation_mursData.Surface_isolant == "") {
        this.isLoading = false;
        this.$emit("changeStep", 6, true);
      }
      await this.isolation_mursAdd();
      if (this.errorMessage !== "") {
        this.isLoading = false;

        return;
      }
      this.isLoading = false;
      this.$emit("changeStep", 6, true);
    },
    goToPrevStep() {
      if(this.visibleTabsLength<=1){
        this.$emit("changePrev",true);
      }else {
        this.$emit("changePrev",false);
      }
      this.$emit("changeStep", 4);
    },
  },
};
</script>
<style scoped>
.custom-placeholder::placeholder {
  font-size: 13px;
}
.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-input {
  box-shadow: none;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>

<template>
  <div>
    <div class="row mt-4">
      <div class="col-md-6 col-lg-6 col-sm-12">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>
        <div v-else>
          <div class="background-white mt-3">
            <div class="title">
              <div class="position">{{$t("information_technique")}}</div>
            </div>
          </div>

          <div class="center borderPointier mt-3 py-3">
            <div>
              <form @submit.prevent="chaudièreBiomasseAjouter">
                <div class="center mt-3 p-3">
                  <div>
                    <b-form-radio
                      v-model="selected"
                      class="mb-3"
                      name="radios1"
                      value="A"
                    >
                     {{$t("appareil_Flamme_verte")}}
                    </b-form-radio>

                    <b-form-radio
                      v-model="selected"
                      class="mb-3"
                      name="radios1"
                      value="B"
                    >
                      {{$t("Autre")}}
                    </b-form-radio>
                  </div>

                  <b-form-group
                    :label="$t('Alimentation')"
                    label-for="select-chaudiere"
                    class="mt-3"
                  >
                  <b-form-select
                  v-model="selectedAlimentation"
                  :options="options_alimentation"
                  id="select-chaudiere"
                  class="custom-input"
                  @change="handleAlimentation"
                >
                </b-form-select>
                
                

                    <!-- Affichage conditionnel en fonction de l'alimentation sélectionnée -->
                    <div v-if="selectedAlimentation === 'automatique'">
                      <b-form-group
                      :label="$t('Volume')"
                      label-for="Volume"
                     
                    >
                      <b-form-input
                        type="number"
                        min="0"
                        @paste="(event) => onPaste(event, 'chaudièreBiomasseData.volume')"
                         @keydown="(event) => onKeyDownFloat(event)"
                        v-model="chaudièreBiomasseData.volume"
                        :placeholder="$t('Volume_en_litre')"
                        class="form-input custom-placeholder custom-input mt-3"
                      ></b-form-input>
                      </b-form-group>
                    </div>

                    <div v-else-if="selectedAlimentation === 'manuel'">
                 
                      <b-form-group
                      :label="$t('Ballon_tampon_associé')"
                      label-for="Ballon"
                     
                    >
                      <b-form-select
                        v-model="chaudièreBiomasseData.ballon_existant"
                        :options="options_ballon_existant"
                        class="form-input custom-placeholder custom-input mt-3"
                      >
                      </b-form-select></b-form-group>
                    </div>
                  </b-form-group>

                  <div v-if="selected === 'B'">
                    <div class="fieldset mt-3">
                      <div class="legend mb-2">
                        {{ $t("emissions_composés_organiques_gazeux") }}
                      </div>
                      <b-form-input
                        type="number"
                        min="0"
                        @paste="(event) => onPaste(event, 'chaudièreBiomasseData.emmisions_saisonniere')"
                          @keydown="(event) => onKeyDownFloat(event)"
                        v-model="chaudièreBiomasseData.emmisions_saisonniere"
                        :placeholder="$t('composés_organiques_gazeux')"
                        class="form-input custom-placeholder custom-input"
                      >
                      </b-form-input>
                    </div>

                    <div class="fieldset mt-3">
                      <div class="legend mb-2">
                        {{ $t("emissions_particules") }}
                      </div>
                      <b-form-input
                        type="number"
                        min="0"
                        @paste="(event) => onPaste(event, 'chaudièreBiomasseData.emissions_particules')"

                           @keydown="(event) => onKeyDownFloat(event)"
                        v-model="chaudièreBiomasseData.emissions_particules"
                        :placeholder="$t('emissions_de_particules')"
                        class="form-input custom-placeholder custom-input"
                      >
                      </b-form-input>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>
        <div v-else>
          <div class="background-white mt-3">
            <div class="title">
              <div class="position">{{$t("information_technique")}}</div>
            </div>
          </div>
          <div class="borderPointier mt-3 pt-3 px-3">
            <div>
              <form @submit.prevent="chaudièreBiomasseAjouter">
                <div class="">
                  <div v-if="selected === 'B'">
                    <div class="fieldset mt-3">
                      <div class="legend mb-2">
                        {{ $t("emissions_monoxyd") }}
                      </div>
                      <b-form-input
                        type="number"
                        @paste="(event) => onPaste(event, 'chaudièreBiomasseData.emissions_CO')"

                          @keydown="(event) => onKeyDownFloat(event)"
                        min="0"
                        v-model="chaudièreBiomasseData.emissions_CO"
                        :placeholder="$t('Monoxyde_carbone')"
                        class="form-input custom-placeholder custom-input"
                      >
                      </b-form-input>
                    </div>

                    <div class="fieldset mt-3">
                      <div class="legend mb-2">
                        {{ $t("emissions_azote") }}
                      </div>
                      <b-form-input
                        type="number"
                        min="0"
                        @paste="(event) => onPaste(event, 'chaudièreBiomasseData.emissions_NOx')"

                          @keydown="(event) => onKeyDownFloat(event)"
                        v-model="chaudièreBiomasseData.emissions_NOx"
                        :placeholder="$t('Oxydes_azote')"
                        class="form-input custom-placeholder custom-input"
                      >
                      </b-form-input>
                    </div>
                  </div>
                  <div class="fieldset my-3">
                    <div class="legend mb-2">
                      {{ $t("Puissance") }}
                    </div>
                    <b-form-input
                      type="number"
                      min="0"
                      @paste="(event) => onPaste(event, 'chaudièreBiomasseData.puissance')"

                        @keydown="(event) => onKeyDownFloat(event)"
                      v-model="chaudièreBiomasseData.puissance"
                      :placeholder="$t('Puissance')"
                      class="form-input custom-placeholder custom-input"
                    >
                    </b-form-input>
                  </div>
                  <div class="fieldset my-3">
                    <div class="legend mb-2">
                      {{ $t("efficacité_énergétique_saisonnière") }}
                    </div>
                    <b-form-input
                      type="number"
                      min="0"
                      @paste="(event) => onPaste(event, 'chaudièreBiomasseData.efficacite_energetique')"
                      @keydown="(event) => onKeyDownFloat(event)"
                      v-model="chaudièreBiomasseData.efficacite_energetique"
                      :placeholder="$t('efficacité_énergétique_saisonnière')"
                      class="form-input custom-placeholder custom-input"
                    >
                    </b-form-input>
                  </div>

                  <div class="fieldset my-3">
                    <div class="legend mb-2">
                      {{ $t("Classe_régulateur") }}
                    </div>
                    <b-form-input
                      type="text"
                      v-model="chaudièreBiomasseData.classe"
                      :placeholder="$t('Classe_régulateur')"
                      class="form-input custom-placeholder custom-input"
                    >
                    </b-form-input>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- <div v-if="show" class="d-flex justify-content-end mt-4">
            <b-button v-if="this.visibleTabsLength=1" @click="goToPrevStep"
             class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToStep"
        class="button-suivant-style m-3"
      >
        <template v-if="isLoading">
          <div class="loading ml-2">
            <div class="spinner-border spinner-border-sm" role="status"></div>
          </div>
        </template>
      
        <template v-else>
          {{ buttonText }}
        </template>
      </b-button>
    </div> -->
        </div>
      </div>
      
  </div>

    <div  v-if="!show" class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
      {{$t("NEXT")}}
      <div class="loading ml-2" v-if="isLoading">
        <div class="spinner-border spinner-border-sm" role="status"></div>
      </div>
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
    <Snackbar ref="snackbar" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Snackbar from "@/components/ui/errorSnackbar.vue";
import { handlePaste, preventExceedingLengthFloat } from '@/utils/inputUtils'; 
export default {
  components: {
    Snackbar,
  },
  props: {
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    visibleTabsLength: {
      type: Number,
      required: true,
    },
    progress: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  
  data() {
    return {
      isCompleted: false,
      isLoading:false,
      type: null,
      normes: null,
      selected: "A",
      selectedAlimentation: null,
  
      options_alimentation: [
        { value: null, text: this.$t("Choisissez_option")  ,disabled: true},
        { value: 'automatique', text: this.$t("automatique") },
        { value: 'manuel', text: this.$t("manuel") }
      ],
      options_ballon_existant: [
        { value: true, text: this.$t("YES")},
        { value: false, text: this.$t("NO") },
       
      ],
      chaudièreBiomasseData: null,
      chaudièreBiomasseExist: false,
    };
  },
  created() {
    if (this.getdevisMonoFiche && this.getdevisMonoFiche.data) {
      this.chaudièreBiomasseData = this.initializechaudièreBiomasse();

      this.chaudièreBiomasseData.ballon_existant = this.chaudièreBiomasseData.ballon_existant === 1 ? true : false;

      this.setSelectedValues();
    }
  },
  
  computed: {
    ...mapGetters(["getdevisMonoFiche"]),
    normeOptions() {
      return this.type ? this.normesByType[this.type] : [];
    },
    buttonText() {
      return this.visibleTabsLength <= 1 ? this.$t("NEXT") : this.$t("CONFIRMER");
    },
  },
 watch: {
    getdevisMonoFiche: {
      handler(newDevis) {
        if (newDevis && newDevis.data) {
          this.chaudièreBiomasseData = this.initializechaudièreBiomasse();
          this.chaudièreBiomasseExist = newDevis.data?.devis.chaudiere_biomasse !== null;
        
          this.setSelectedValues();
          if (this.chaudièreBiomasseExist) {
            this.isCompleted = true;
          }
        }
      },
      deep: true,
      immediate: true,
    },
    'chaudièreBiomasseData.type_alimentation': {
  handler(newValue) {
    this.selectedAlimentation = newValue ? 'automatique' : 'manuel';
  },
 
  immediate: true,
},

 
  },
  methods: {
    ...mapActions([
      "setDevisMonoFiche",
      "editchaudiereBiomasse",
      "storechaudiereBiomasse",
    ]),
    onKeyDownFloat(event,) {
      preventExceedingLengthFloat(event);
    },
    
    onPaste(event, fieldName) {
      handlePaste.call(this, event, fieldName);
    },
   setSelectedValues() {
    
      if (!this.chaudièreBiomasseData.flammeverte ) {
        this.selected = "B";
      } else {
        this.selected = "A";
      }
    },
    initializechaudièreBiomasse() {
  const chaudiereBiomasse = this.getdevisMonoFiche?.data?.devis.chaudiere_biomasse || {};
  
  this.selectedAlimentation = chaudiereBiomasse.type_alimentation;
  
  return {
    id: chaudiereBiomasse.id,
    flammeverte: chaudiereBiomasse.flammeverte || false,
    type_alimentation: chaudiereBiomasse.type_alimentation || "manuel",
    emmisions_saisonniere: chaudiereBiomasse.emmisions_saisonniere || null,
    emissions_particules: chaudiereBiomasse.emissions_particules || null,
    emissions_CO: chaudiereBiomasse.emissions_CO || null,
    emissions_NOx: chaudiereBiomasse.emissions_NOx || null,
    volume: chaudiereBiomasse.volume || 0,
    classe: chaudiereBiomasse.classe || "",
    efficacite_energetique: chaudiereBiomasse.efficacite_energetique || null,
    puissance: chaudiereBiomasse.puissance || null,
    ballon_existant: chaudiereBiomasse.ballon_existant || false,
  };
},
handleAlimentation() {

    if (this.selectedAlimentation === 'automatique') {
      this.chaudièreBiomasseData.type_alimentation = 'automatique';
    } else if (this.selectedAlimentation === 'manuel') {
      this.chaudièreBiomasseData.type_alimentation = 'manuel';
    }
  },
  async chaudièreBiomasseAjouter() {
      if (this.selected === "B") {
        this.chaudièreBiomasseData.flammeverte = false;
      } else {
        this.chaudièreBiomasseData.flammeverte = true;
        this.chaudièreBiomasseData.emissions_CO= 0;
          this.chaudièreBiomasseData.emissions_NOx= 0;
          this.chaudièreBiomasseData.emissions_particules= 0
          this.chaudièreBiomasseData.emmisions_saisonniere= 0;
          
      }
      const response = await this.storechaudiereBiomasse(this.chaudièreBiomasseData);
      if (response && response.data) {
      this.getdevisMonoFiche.data.devis.chaudiere_biomasse_id =
        response.data.id;
      this.getdevisMonoFiche.data.devis.chaudiere_biomasse = {
        id: response.data.id,
        type_alimentation: this.chaudièreBiomasseData.type_alimentation,
        emmisions_saisonniere: this.chaudièreBiomasseData.emmisions_saisonniere,
        emissions_particules: this.chaudièreBiomasseData.emissions_particules,
        flammeverte: this.chaudièreBiomasseData.flammeverte,
        emissions_CO:this.chaudièreBiomasseData.emissions_CO,
        emissions_NOx: this.chaudièreBiomasseData.emissions_NOx,
        ballon_existant: this.chaudièreBiomasseData.ballon_existant,
        puissance:this.chaudièreBiomasseData.puissance,
        efficacite_energetique:this.chaudièreBiomasseData.efficacite_energetique,
        classe:this.chaudièreBiomasseData.classe,
        volume:this.chaudièreBiomasseData.volume
      };
      this.setDevisMonoFiche(this.getdevisMonoFiche);
   } else {return}},

    async chaudièreBiomasseAdd() {
 
      if (this.chaudièreBiomasseExist) {
        if (this.selected === "B") {
          this.chaudièreBiomasseData.flammeverte = false;
        } else {
          this.chaudièreBiomasseData.flammeverte = true;
          this.chaudièreBiomasseData.emissions_CO= 0;
          this.chaudièreBiomasseData.emissions_NOx= 0;
          this.chaudièreBiomasseData.emissions_particules= 0
          this.chaudièreBiomasseData.emmisions_saisonniere= 0;
       
        }
        if(this.chaudièreBiomasseData.type_alimentation==='manuel'){
          this.chaudièreBiomasseData.volume=0;
        }
        const chaudiere = {
          ...this.chaudièreBiomasseData,
          id: this.getdevisMonoFiche.data.devis.chaudiere_biomasse.id,
        };

        const reponse = await this.editchaudiereBiomasse(chaudiere);
        this.getdevisMonoFiche.data.devis.chaudiere_biomasse = {
          id: this.getdevisMonoFiche.data.devis.chaudiere_biomasse.id,
          type_alimentation: this.chaudièreBiomasseData.type_alimentation,
        emmisions_saisonniere: this.chaudièreBiomasseData.emmisions_saisonniere,
        emissions_particules: this.chaudièreBiomasseData.emissions_particules,
        flammeverte: this.chaudièreBiomasseData.flammeverte,
        emissions_CO:this.chaudièreBiomasseData.emissions_CO,
        emissions_NOx: this.chaudièreBiomasseData.emissions_NOx,
        ballon_existant: this.chaudièreBiomasseData.ballon_existant,
        puissance:this.chaudièreBiomasseData.puissance,
        efficacite_energetique:this.chaudièreBiomasseData.efficacite_energetique,
        classe:this.chaudièreBiomasseData.classe,
        volume:this.chaudièreBiomasseData.volume
          };
        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return reponse;
      }

       await this.chaudièreBiomasseAjouter();
       this.setDevisMonoFiche(this.getdevisMonoFiche);
    },

    async goToStep() {
      this.isLoading=true;
   if(!this.chaudièreBiomasseData.efficacite_energetique || !this.chaudièreBiomasseData.puissance){
    this.isLoading=false;
    this.$refs.snackbar.showSnackbar("Tous_obligatoires");
    this.isCompleted = false;
    return;
   }
      await this.chaudièreBiomasseAdd();

      this.isLoading=false;
      this.isCompleted = true;
      if(this.visibleTabsLength<=1){
        this.$emit("change",true);
      }else {
        this.$emit("change",false);
      }
    },

    async goToNextStep() {
      this.isLoading=true;
   if(!this.chaudièreBiomasseData.efficacite_energetique || !this.chaudièreBiomasseData.puissance){
    this.isLoading=false;
    this.$refs.snackbar.showSnackbar("Tous_obligatoires");
        
        return;
   }
      await this.chaudièreBiomasseAdd();

      this.isLoading=false;
      this.$emit("changeStep", 6, true);
    },
    goToPrevStep() {
      if(this.visibleTabsLength<=1){
        this.$emit("changePrev",true);
      }else {
        this.$emit("changePrev",false);
      }
      this.$emit("changeStep", 4);
    },
  },
};
</script>
<style scoped>
.custom-placeholder::placeholder {
  font-size: 13px;
}
.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-input {
  box-shadow: none;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>

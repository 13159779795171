<template>
  <div>
    <div>
      <div class="row my-4 mx-1 justify-content-center">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>
        <div v-else class="col-lg-8 col-sm-12 col-md-10 borderPointier">
          <div class="background-white">
            <div class="title">
              <div class="position">{{ $t("conditions_technique") }}</div>
            </div>
          </div>
          <form @submit.prevent="systeme_regulationAdd" class="my-3">
            <b-form-group label="Le type de système concerné">
              <b-form-select
                v-model="systeme_regulation_horaire.concerne"
                :options="concerneOptions"
                name="concerne"
              />
            </b-form-group>

            <b-form-group label="Classe de régulation">
              <b-form-select
                v-model="systeme_regulation_horaire.class"
                :options="classOptions"
                name="Classe de régulation"
              />
            </b-form-group>

            <b-form-group label="L'ancien système est de classe C ">
              <b-form-radio-group
                v-model="systeme_regulation_horaire.ancient_system_class_c"
                :options="ancient_system_class_cOptions"
                name="ancient_system_class_c"
              />
            </b-form-group>

            <b-form-group label="les pièces sont équipées d'émetteurs ">
              <b-form-radio-group
                v-model="systeme_regulation_horaire.pieces_equipees_emetteur"
                :options="pieces_equipees_emetteurOptions"
                name="pieces_equipees_emetteur"
              />
            </b-form-group>
            <b-form-group label="Dispositif central ">
              <b-form-radio-group
                v-model="systeme_regulation_horaire.comporte_appareil_central"
                :options="comporte_appareil_centralOptions"
                name="comporte_appareil_central"
              />
            </b-form-group>

            <b-form-group label="dispose d'une sonde de mesure">
              <b-form-radio-group
                v-model="systeme_regulation_horaire.dispose_sonde_mesure"
                :options="dispose_sonde_mesureOptions"
                name="dispose_sonde_mesure"
              />
            </b-form-group>
            <b-form-group label="équipé d'un dispositif de régulation">
              <b-form-radio-group
                v-model="systeme_regulation_horaire.possede_dispositif_regulant"
                :options="possede_dispositif_regulantOptions"
                name="possede_dispositif_regulant"
              />
            </b-form-group>

            <b-form-group label="fonctionner localement au minimum">
              <b-form-radio-group
                v-model="systeme_regulation_horaire.effectuer_local_minima"
                :options="effectuer_local_minimaOptions"
                name="effectuer_local_minima"
              />
            </b-form-group>
            <b-form-group label="Dérogation locale possible">
              <b-form-radio-group
                v-model="
                  systeme_regulation_horaire.deroger_localement_consignes
                "
                :options="deroger_localement_consignesOptions"
                name="deroger_localement_consignes"
              />
            </b-form-group>

            <b-form-group
              label="Possibilité de fonctionnement en cas de perte de connexion"
            >
              <b-form-radio-group
                v-model="systeme_regulation_horaire.fonctionner_perte_connexion"
                :options="fonctionner_perte_connexionOptions"
                name="fonctionner_perte_connexion"
              />
            </b-form-group>
            <b-form-group label="le boîtier est équipé d'émetteurs">
              <b-form-radio-group
                v-model="systeme_regulation_horaire.logement_equipees_emetteur"
                :options="logement_equipees_emetteurOptions"
                name="logement_equipees_emetteur"
              />
            </b-form-group>

            <b-form-group label="Planchers/Plafonds chauffants">
              <b-form-radio-group
                v-model="
                  systeme_regulation_horaire.planchers_plafonds_chauffants
                "
                :options="planchers_plafonds_chauffantsOptions"
                name="planchers_plafonds_chauffants"
              />
            </b-form-group>
            <b-form-group label="Remplacement du système existant ">
              <b-form-radio-group
                v-model="systeme_regulation_horaire.remlpacement"
                :options="remlpacementOptions"
                name="remlpacement"
              />
            </b-form-group>
            <b-form-group label="Surface habitable (m²)">
              <b-form-input
                v-model="systeme_regulation_horaire.surface_chauffe"
                @paste="
                  (event) =>
                    onPaste(event, 'systeme_regulation_horaire.surface_chauffe')
                "
                @keydown="(event) => onKeyDownFloat(event)"
                min="0"
                type="number"
                placeholder="Surface en m²"
              />
            </b-form-group>
          </form>
          <!-- <div v-if="show" class="d-flex justify-content-end mt-4">
            <b-button v-if="this.visibleTabsLength=1" @click="goToPrevStep"
             class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToStep"
        class="button-suivant-style m-3"
      >
        <template v-if="isLoading">
          <div class="loading ml-2">
            <div class="spinner-border spinner-border-sm" role="status"></div>
          </div>
        </template>
     
        <template >
          {{ buttonText }}
        </template>
      </b-button>
    </div> -->
        </div>
      </div>
    
    </div>
   
    <div v-if="!show" class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
        {{ $t("NEXT") }}
        <div class="loading ml-2" v-if="isLoading">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
    <Snackbar ref="snackbar" class="mt-5" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import domain from "@/environment";
import axios from "axios";
import Snackbar from "@/components/ui/errorSnackbar.vue";

import {
  handlePaste,
  preventExceedingLength,
  preventExceedingLengthFloat,
} from "@/utils/inputUtils";
export default {
  components: {
    Snackbar,
  },
  props: {
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    visibleTabsLength: {
      type: Number,
      required: true,
    },
    progress: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      
      isCompleted: false,
      errorMessage: "",
      isLoading: false,
      systeme_regulation_horaire: null,
      systeme_regulation: false,
      remlpacementOptions: [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
      concerneOptions: [
        {
          text: "système de chauffage ne disposant pas de régulation.",
          value: "système de chauffage ne disposant pas de régulation.",
        },
        {
          text: "système de régulation existant.",
          value: "système de régulation existant.",
        },
      ],

      ancient_system_class_cOptions: [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
      classOptions: [
        { value: "A", text: "Classe A" },
        { value: "B", text: "Classe B" },
      ],
      pieces_equipees_emetteurOptions: [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
      comporte_appareil_centralOptions: [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],

      dispose_sonde_mesureOptions: [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
      possede_dispositif_regulantOptions: [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
      effectuer_local_minimaOptions: [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
      deroger_localement_consignesOptions: [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
      fonctionner_perte_connexionOptions: [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
      logement_equipees_emetteurOptions: [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
      planchers_plafonds_chauffantsOptions: [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
    };
  },
  created() {
    if (this.getdevisMonoFiche && this.getdevisMonoFiche.data) {
      this.systeme_regulation_horaire =
        this.initializesysteme_regulation_horaire();
    }
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
    }),
    ...mapGetters(["getdevisMonoFiche"]),
    buttonText() {
      return this.visibleTabsLength <= 1 ? this.$t("NEXT") : this.$t("CONFIRMER");
    },
  },

  watch: {
    getdevisMonoFiche: {
      handler(newDevis) {
        if (newDevis && newDevis.data) {
          this.systeme_regulation_horaire =
            this.initializesysteme_regulation_horaire();
          this.systeme_regulation =
            newDevis.data?.devis.systeme_regulation_horaire !== null;
          if (this.systeme_regulation) {
            this.isCompleted = true;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["setDevisMonoFiche"]),
    onKeyDown(event) {
      preventExceedingLength(event);
    },
    onKeyDownFloat(event) {
      preventExceedingLengthFloat(event);
    },
    onPaste(event, fieldName) {
      handlePaste.call(this, event, fieldName);
    },
    async postsysteme_regulation(payload) {
      try {
        const response = await axios.post(
          domain + `/systeme_regulation_horaire`,
          payload,
          {
            headers: {
              Authorization: `Bearer ` + this.token,
            },
          }
        );
        return response.data.data;
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.errorMessage = error.response.data.message;
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          this.errorMessage = error.response.data.errors.join(", ");
        } else {
          this.errorMessage = "Vérifiez vos données.";
        }

        
        this.$refs.snackbar.showSnackbar(this.errorMessage);

        return null;
      }
    },

    async putsysteme_regulation(payload) {
      try {
        const response = await axios.put(
          domain + `/systeme_regulation_horaire/${payload.id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ` + this.token,
            },
          }
        );
        return response.data.data;
      } catch (error) {
        throw new Error("Failed to put isolation combles toiture.");
      }
    },
    initializesysteme_regulation_horaire() {
      const systeme_regulation_horaire =
        this.getdevisMonoFiche?.data?.devis.systeme_regulation_horaire || {};

      return {
        id: systeme_regulation_horaire.id,
        concerne:
          systeme_regulation_horaire.concerne ||
          "système de chauffage ne disposant pas de régulation.",
        ancient_system_class_c:
          systeme_regulation_horaire.ancient_system_class_c || true,
        class: systeme_regulation_horaire.class || "A",
        pieces_equipees_emetteur:
          systeme_regulation_horaire.pieces_equipees_emetteur || true,
        comporte_appareil_central:
          systeme_regulation_horaire.comporte_appareil_central || true,
        dispose_sonde_mesure:
          systeme_regulation_horaire.dispose_sonde_mesure || true,
        possede_dispositif_regulant:
          systeme_regulation_horaire.possede_dispositif_regulant || true,
        effectuer_local_minima:
          systeme_regulation_horaire.effectuer_local_minima || true,
        surface_chauffe: systeme_regulation_horaire.surface_chauffe || true,
        deroger_localement_consignes:
          systeme_regulation_horaire.deroger_localement_consignes || true,
        logement_equipees_emetteur:
          systeme_regulation_horaire.logement_equipees_emetteur || true,
        planchers_plafonds_chauffants:
          systeme_regulation_horaire.planchers_plafonds_chauffants || true,
        fonctionner_perte_connexion:
          systeme_regulation_horaire.fonctionner_perte_connexion || true,
        remlpacement: systeme_regulation_horaire.remlpacement || true,
      };
    },
    async isolationAdd() {
      const response = await this.postsysteme_regulation(
        this.systeme_regulation_horaire
      );
      if (response.id !== null) {
        this.getdevisMonoFiche.data.devis.systeme_regulation_horaire_id =
          response.id;
        this.getdevisMonoFiche.data.devis.systeme_regulation_horaire = {
          id: this.getdevisMonoFiche.data.devis.systeme_regulation_horaire_id,
          concerne: this.systeme_regulation_horaire.concerne,
          ancient_system_class_c:
            this.systeme_regulation_horaire.ancient_system_class_c,
          class: this.systeme_regulation_horaire.class,
          pieces_equipees_emetteur:
            this.systeme_regulation_horaire.pieces_equipees_emetteur,
          comporte_appareil_central:
            this.systeme_regulation_horaire.comporte_appareil_central,
          dispose_sonde_mesure:
            this.systeme_regulation_horaire.dispose_sonde_mesure,
          possede_dispositif_regulant:
            this.systeme_regulation_horaire.possede_dispositif_regulant,
          effectuer_local_minima:
            this.systeme_regulation_horaire.effectuer_local_minima,
          surface_chauffe: this.systeme_regulation_horaire.surface_chauffe,
          deroger_localement_consignes:
            this.systeme_regulation_horaire.deroger_localement_consignes,
          logement_equipees_emetteur:
            this.systeme_regulation_horaire.logement_equipees_emetteur,
          planchers_plafonds_chauffants:
            this.systeme_regulation_horaire.planchers_plafonds_chauffants,
          fonctionner_perte_connexion:
            this.systeme_regulation_horaire.fonctionner_perte_connexion,
          remlpacement: this.systeme_regulation_horaire.remlpacement,
        };
        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return response;
      } else {
        this.isLoading = false;

        return;
      }
    },

    async systeme_regulationAdd() {
      if (!this.systeme_regulation) {
        const chauffe = {
          ...this.systeme_regulation_horaire,
          id: this.getdevisMonoFiche.data.devis.systeme_regulation_horaire.id,
        };

        const reponse = await this.putsysteme_regulation(chauffe);
        this.getdevisMonoFiche.data.devis.systeme_regulation_horaire = {
          id: this.getdevisMonoFiche.data.devis.systeme_regulation_horaire.id,
          concerne: this.systeme_regulation_horaire.concerne,
          ancient_system_class_c:
            this.systeme_regulation_horaire.ancient_system_class_c,
          class: this.systeme_regulation_horaire.class,
          pieces_equipees_emetteur:
            this.systeme_regulation_horaire.pieces_equipees_emetteur,
          comporte_appareil_central:
            this.systeme_regulation_horaire.comporte_appareil_central,
          dispose_sonde_mesure:
            this.systeme_regulation_horaire.dispose_sonde_mesure,
          possede_dispositif_regulant:
            this.systeme_regulation_horaire.possede_dispositif_regulant,
          effectuer_local_minima:
            this.systeme_regulation_horaire.effectuer_local_minima,
          surface_chauffe: this.systeme_regulation_horaire.surface_chauffe,
          deroger_localement_consignes:
            this.systeme_regulation_horaire.deroger_localement_consignes,
          logement_equipees_emetteur:
            this.systeme_regulation_horaire.logement_equipees_emetteur,
          planchers_plafonds_chauffants:
            this.systeme_regulation_horaire.planchers_plafonds_chauffants,
          fonctionner_perte_connexion:
            this.systeme_regulation_horaire.fonctionner_perte_connexion,
          remlpacement: this.systeme_regulation_horaire.remlpacement,
        };

        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return reponse;
      }
      await this.isolationAdd();
    },
    async goToStep() {
      this.isLoading = true;

      await this.systeme_regulationAdd();
      if (this.errorMessage !== "") {
        this.isLoading = false;

        this.$refs.snackbar.showSnackbar("Tous_obligatoires");
        this.isCompleted = false;
        return;
      }
      this.isLoading = false;
      this.isCompleted = true;
      if(this.visibleTabsLength<=1){
        this.$emit("change",true);
      }else {
        this.$emit("change",false);
      }
    },

    async goToNextStep() {
      this.isLoading = true;

      await this.systeme_regulationAdd();
      if (this.errorMessage !== "") {
        this.isLoading = false;
        this.$refs.snackbar.showSnackbar("Tous_obligatoires");

        return;
      }
      this.isLoading = false;
      this.$emit("changeStep", 6, true);
    },
    goToPrevStep() {
      if(this.visibleTabsLength<=1){
        this.$emit("changePrev",true);
      }else {
        this.$emit("changePrev",false);
      }
      this.$emit("changeStep", 4);
    },
  },
};
</script>
<style scoped>
.custom-placeholder::placeholder {
  font-size: 13px;
}
.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-input {
  box-shadow: none;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>


<template>
  <div>
    <div>
      <div class="row my-4 mx-1 justify-content-center">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>
        <div v-else class="col-lg-8 col-sm-12 col-md-10 borderPointier">
          <div class="background-white">
            <div class="title">
              <div class="position">{{ $t("conditions_technique") }}</div>
            </div>
          </div>
          <form @submit.prevent="submitForm">
                  <b-form-group :label="$t('Type_pompe')">
                    <b-form-select
                      v-model="systemeChauffageData.denomination_temperature"
                      :options="optionsType"
                      class="form-input custom-placeholder custom-input"
                    >
                    </b-form-select>
                  </b-form-group>
      
                  <div class="fieldset mt-3">
                    <div class="legend mb-2">{{$t("Coeffcient_performance")}}</div>
                    <b-form-input
                      type="number"
                      min="0"
                      @paste="(event) => onPaste(event, 'systemeChauffageData.coefficient_performance')"

                       @keydown="(event) => onKeyDownFloat(event)"
                      v-model="systemeChauffageData.coefficient_performance"
                      :placeholder="$t('Coeffcient')"
                      class="form-input custom-placeholder custom-input"
                    >
                    </b-form-input>
                  </div>
      
                  <div class="fieldset mt-3">
                    <div class="legend mb-2">
                      {{$t('Efficacité_calculer_selon_réglément')}}
                   
                    </div>
                    <b-form-input
                      type="number"
                      min="0"
                      @paste="(event) => onPaste(event, 'systemeChauffageData.efficacite')"

                   @keydown="(event) => onKeyDownFloat(event)"
                      v-model="systemeChauffageData.efficacite"
                      :placeholder="$t('Efficacité')"
                      class="form-input custom-placeholder custom-input"
                    >
                    </b-form-input>
                  </div>
      
                  <div class="fieldset mt-3">
                    <div class="legend mb-2">{{$t('Surface_chauffe')}}</div>
                    <b-form-input
                      type="number"
                      min="0"
                       @paste="(event) => onPaste(event, 'systemeChauffageData.surface_chauffee')"
                       @keydown="(event) => onKeyDownFloat(event)"
                      v-model="systemeChauffageData.surface_chauffee"
                      :placeholder="$t('Surface_chauffe')"
                      class="form-input custom-placeholder custom-input"
                    ></b-form-input>
                  </div>
      
                  <b-form-group :label="$t('Régulateur')">
                    <b-form-input
                    type="text"
                   :placeholder="$t('Régulateur')"
                    v-model="systemeChauffageData.regulateur_classe"
                    class="form-input custom-placeholder custom-input"
                  ></b-form-input>
                
                  </b-form-group>
      
                 
          </form>
          <!-- <div v-if="show" class="d-flex justify-content-end mt-4">
            <b-button v-if="this.visibleTabsLength=1" @click="goToPrevStep"
             class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToStep"
        class="button-suivant-style m-3"
      >
        <template v-if="isLoading">
          <div class="loading ml-2">
            <div class="spinner-border spinner-border-sm" role="status"></div>
          </div>
        </template>
       
        <template v-else>
          {{ buttonText }}
        </template>
      </b-button>
    </div> -->
        </div>
      </div>
     
    </div>
    <!-- <div v-if="show" class="d-flex justify-content-end mt-4">
          
      <b-button
        type="submit"
        @click="goToStep"
        class="button-suivant-style m-3"
      >
        <template v-if="isLoading">
          <div class="loading ml-2">
            <div class="spinner-border spinner-border-sm" role="status"></div>
          </div>
        </template>
       
        <template v-else>
          {{ buttonText }}
        </template>
      </b-button>
    </div> -->
    <div v-if="!show" class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{$t("PREVIOUS")}}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
      {{$t("NEXT")}}
      <div class="loading ml-2" v-if="isLoading">
        <div class="spinner-border spinner-border-sm" role="status"></div>
      </div>
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
    <Snackbar ref="snackbar" />
  </div>
</template>
<script>
import Snackbar from "@/components/ui/errorSnackbar.vue";

  import { mapGetters, mapActions } from "vuex";
import { handlePaste, preventExceedingLengthFloat } from '@/utils/inputUtils'; 
  export default {
    components: {
    Snackbar,
  },
    props: {
   
    show: {
      type: Boolean,
      default:false,
      required: false,
    },
    visibleTabsLength: {
      type: Number,
      required: true,
    },
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
   
    progress: {
      type: Boolean,
      default: false,
    },
  },
    data() {
      return {
        isCompleted: false,
        form: {},
        isLoading:false,
        systemeChauffageData: null,
        systemAdd: false,
        optionsType: [
          { value: "moyenne", text: this.$t("Temperature_Moyenne") },
          { value: "haute", text: this.$t("Température_Haute") },
          { value: "basse", text: this.$t("Température_Basse") },
        ],
        optionsTypeClient: [
          { value: "I", text: this.$t("I") },
          { value: "II", text: this.$t("II") },
          { value: "III", text: this.$t("III") },
          { value: "IV", text: this.$t("IV") },
          { value: "V", text: this.$t("V") },
        ],
      };
    },
    computed: {
    ...mapGetters([
      "getdevisMonoFiche",
      
    ]), buttonText() {
      return this.visibleTabsLength <= 1 ? this.$t("NEXT") : this.$t("CONFIRMER");
    },},
    created() {
    if (this.getdevisMonoFiche && this.getdevisMonoFiche.data) {
      this.systemeChauffageData = this.initializeSystemeChauffageData();
    
    }
  },
  watch: {
    getdevisMonoFiche: {
      handler(newDevis) {
        if (newDevis && newDevis.data) {
          this.systemeChauffageData = this.initializeSystemeChauffageData();
          this.systemAdd = newDevis.data?.devis.systeme_chauffage !== null;
          if (this.systemAdd) {
            this.isCompleted = true;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
      ...mapActions(["editSystemeChauffage", "storeSystemeChauffage","setDevisMonoFiche"]),
 onKeyDownFloat(event) {
    preventExceedingLengthFloat(event);
    },
 
    onPaste(event, fieldName) {
      handlePaste.call(this, event, fieldName);
    },
      initializeSystemeChauffageData() {
        const systemeChauffage =
          this.getdevisMonoFiche?.data?.devis?.systeme_chauffage || {};
        return {
          denomination_temperature:
            systemeChauffage.denomination_temperature || "",
          coefficient_performance:
            parseInt(systemeChauffage.coefficient_performance) || "",
          efficacite: parseInt(systemeChauffage.efficacite) || "",
          surface_chauffee: parseInt(systemeChauffage.surface_chauffee) || "",
          regulateur_classe: systemeChauffage.regulateur_classe,
          };
      },
      async submitForm() {
        const response = await this.storeSystemeChauffage(
          this.systemeChauffageData
        );
        this.getdevisMonoFiche.data.devis.systeme_chauffage_id = response.data.id;
        this.getdevisMonoFiche.data.devis.systeme_chauffage={
          id:response.data.id,
          denomination_temperature:this.systemeChauffageData.denomination_temperature,
          coefficient_performance:this.systemeChauffageData.coefficient_performance,
          efficacite:this.systemeChauffageData.efficacite,
          surface_chauffee:this.systemeChauffageData.surface_chauffee,
          regulateur_classe:this.systemeChauffageData.regulateur_classe,
         }
        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return response;
      },
      async edit() {
 
        if (!this.systemAdd) {
          await this.submitForm();
        } else {
          const systeme = {
            ...this.systemeChauffageData,
            id: this.getdevisMonoFiche.data?.devis?.systeme_chauffage.id,
          };
  
          const res = await this.editSystemeChauffage(systeme);
          this.getdevisMonoFiche.data.devis.systeme_chauffage={
          id:this.getdevisMonoFiche.data?.devis?.systeme_chauffage.id,
          denomination_temperature:this.systemeChauffageData.denomination_temperature,
          coefficient_performance:this.systemeChauffageData.coefficient_performance,
          efficacite:this.systemeChauffageData.efficacite,
          surface_chauffee:this.systemeChauffageData.surface_chauffee,
          regulateur_classe:this.systemeChauffageData.regulateur_classe,
        }
          this.setDevisMonoFiche(this.getdevisMonoFiche);
         
          return res;
        }
      },

      async goToStep() {
        this.isLoading=true;
         if(!this.systemeChauffageData?.denomination_temperature||!this.systemeChauffageData?.coefficient_performance||!this.systemeChauffageData?.surface_chauffee||!this.systemeChauffageData?.regulateur_classe){
          this.isLoading=false;
           this.$refs.snackbar.showSnackbar("Tous_obligatoires");
         this.isCompleted = false;
         return;
         }
      await this.edit();
      this.isLoading=false;
      this.isCompleted = true;
      if(this.visibleTabsLength<=1){
        this.$emit("change",true);
      }else {
        this.$emit("change",false);
      }
    },


      async goToNextStep() {
        this.isLoading=true;
        if(!this.systemeChauffageData?.denomination_temperature||!this.systemeChauffageData?.coefficient_performance||!this.systemeChauffageData?.surface_chauffee||!this.systemeChauffageData?.regulateur_classe){
          this.isLoading=false;
           this.$refs.snackbar.showSnackbar("Tous_obligatoires");
         this.isCompleted = false;
         return;
         }
      await this.edit();
      this.isLoading=false;
      this.$emit("changeStep", 6, true);
    },
    goToPrevStep() {
      if(this.visibleTabsLength<=1){
        this.$emit("changePrev",true);
      }else {
        this.$emit("changePrev",false);
      }
      this.$emit("changeStep", 4);
    },
    },
  };
  </script>
<style scoped>
.custom-placeholder::placeholder {
  font-size: 13px;
}
.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-input {
  box-shadow: none;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>

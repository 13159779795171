import { render, staticRenderFns } from "./stepInformationBarTH127.vue?vue&type=template&id=887bc566&scoped=true"
import script from "./stepInformationBarTH127.vue?vue&type=script&lang=js"
export * from "./stepInformationBarTH127.vue?vue&type=script&lang=js"
import style0 from "./stepInformationBarTH127.vue?vue&type=style&index=0&id=887bc566&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "887bc566",
  null
  
)

export default component.exports
<template>
  <div>
    <div class="row mt-4">
      <div class="col-md-6 col-lg-6 col-sm-12">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>
    <div v-else>
        <div class="background-white mt-3">
          <div class="title">
            <div class="position">{{$t("information_technique")}}</div>
          </div>
        </div>

        <div class="center borderPointier mt-3 py-3">
         
          <div >
            <form @submit.prevent="chauffageBoisAjouter">
              <div class="center mt-3 p-3">
                <div>
                  <b-form-radio
                    v-model="selected"
                    class="mb-3"
                    name="radios1"
                    value="A"
                  >
                  {{$t("appareil_Flamme_verte")}}
                  
                  </b-form-radio>
  
                  <b-form-radio
                    v-model="selected"
                    class="mb-3"
                    name="radios1"
                    value="B"
                  >
                  {{$t("Autre")}}
                     
                  </b-form-radio>
                </div>
                <b-form-group
                label="Appareil"
                label-for="select-bois"
                class="mt-3"
              >
              <b-form-select
              v-model="selectedBoisGranule"
              :options="options_bois_granule"
              id="select-bois"
              class="custom-input"
              @change="handleBoisGranuleChange"
            >
            </b-form-select>
          
              </b-form-group>
                <div v-if="selected === 'B'">
               
                  <div class="fieldset mt-3">
                    <div class="legend mb-2">
                      {{$t("rendement_nominal")}}
                    
                    </div>
                    <b-form-input
                      type="number"
                      min="0"
                      @paste="(event) => onPaste(event, 'chauffeBoisData.rendement_nominal')"

                           @keydown="(event) => onKeyDown(event)"
                      v-model="chauffeBoisData.rendement_nominal"
                      :placeholder="$t('rendement')"
                      class="form-input custom-placeholder custom-input"
                    >
                    </b-form-input>
                  </div>

                  <div class="fieldset mt-3">
                    <div class="legend mb-2">
                      {{ $t("emissions_particules") }}
                    </div>
                    <b-form-input
                    min="0"
                      type="number"
                      @paste="(event) => onPaste(event, 'chauffeBoisData.emissions_particules')"
                           @keydown="(event) => onKeyDown(event)"
                      v-model="chauffeBoisData.emissions_particules"
                      :placeholder="$t('emissions_de_particules')"
                      class="form-input custom-placeholder custom-input"
                    >
                    </b-form-input>
                  </div>

                

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> 
      <div class="col-md-6 col-lg-6 col-sm-12">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>
        <div v-else>
        <div class="background-white mt-3">
          <div class="title">
            <div class="position">{{$t("information_technique")}}</div>
          </div>
        </div>
        <div class="borderPointier mt-3 pt-3 px-3">
       
          <div>
            <form @submit.prevent="chauffageBoisAjouter">
              <div class="">
                <div v-if="selected === 'B'">
                  <div class="fieldset mt-3">
                    <div class="legend mb-2">
                      {{ $t("emissions_monoxyd") }}
                    </div>
                    <b-form-input
                      type="number"
                      min="0"
                      @paste="(event) => onPaste(event, 'chauffeBoisData.emissions_CO')"
                        @keydown="(event) => onKeyDown(event)"
                      v-model="chauffeBoisData.emissions_CO"
                      :placeholder="$t('Monoxyde_carbone')"
                      class="form-input custom-placeholder custom-input"
                    >
                    </b-form-input>
                  </div>
                  <div class="fieldset mt-3">
                    <div class="legend mb-2">
                      {{ $t("emissions_azote") }}
                    </div>
                    <b-form-input
                      type="number"
                      min="0"
                      @paste="(event) => onPaste(event, 'chauffeBoisData.emissions_NOx')"
                       @keydown="(event) => onKeyDown(event)"
                      v-model="chauffeBoisData.emissions_NOx"
                      :placeholder="$t('Oxydes_azote')"
                      class="form-input custom-placeholder custom-input"
                    >
                    </b-form-input>
                  </div>
                </div>
                <b-form-group
                  :label="$t('Type_appareil')"
                  label-for="select-type-appareil"
                >
                  <b-form-select
                    v-model="type"
                    :options="typeAppareilOptions"
                    id="select-type-appareil"
                    class="custom-input"
                  >
                  </b-form-select>
                </b-form-group>

                <b-form-group
                  :label="$t('NORM')"
                  label-for="select-norme"
                  v-if="type"
                >
                  <b-form-select
                    v-model="normes"
                    :options="normeOptions"
                    id="select-norme"
                    class="custom-input"
                  >
                  </b-form-select>
                </b-form-group>
              </div>
            </form>
          </div>
        </div>
        <!-- <div v-if="show" class="d-flex justify-content-end mt-4">
          <b-button v-if="this.visibleTabsLength=1" @click="goToPrevStep"
             class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToStep"
        class="button-suivant-style m-3"
      >
        <template v-if="isLoading">
          <div class="loading ml-2">
            <div class="spinner-border spinner-border-sm" role="status"></div>
          </div>
        </template>
     
        <template v-else>
          {{ buttonText }}
        </template>
      </b-button>
    </div> -->
      </div>
      </div>
     
    </div>

    <div  v-if="!show" class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{$t("PREVIOUS")}}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
      {{$t("NEXT")}}
      <div class="loading ml-2" v-if="isLoading">
        <div class="spinner-border spinner-border-sm" role="status"></div>
      </div>
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
    <Snackbar ref="snackbar" />
  </div>
</template>

<script>
import Snackbar from "@/components/ui/errorSnackbar.vue";
import { mapActions, mapGetters } from "vuex";
import { handlePaste, preventExceedingLengthFloat } from '@/utils/inputUtils'; 
export default {
  components: {
    Snackbar,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
      required: false,
    },
    visibleTabsLength: {
      type: Number,
      required: true,
    },
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  
  data() {
    return {
      isCompleted: false,

      isLoading:false,
      type: null,
      normes: null,
      selected: "A",
      selectedBoisGranule: null,
      typeAppareilOptions: [
        { value: null, text: this.$t("Sélectionnez_type_appareil")  ,disabled: true},
        { value: 'poele', text: this.$t("Poêles") },
        { value: 'foyer', text: this.$t("Foyers") },
        { value: 'cuisiniere', text: this.$t("Cuisinières") },
      ],
      options_bois_granule:[ 
                    { value: 'bois_granules', text: this.$t("bois_granules") } ,
                    { value: 'bois_autre_granules', text: this.$t("bois_autre_granules") }, 
                  
        ],
      normesByType: {
        poele: [
          { value: 'NF EN 13240', text: 'NF EN 13240' },
          { value: 'NF EN 14785', text: 'NF EN 14785' },
          { value: 'NF EN 15250', text: 'NF EN 15250' },
        ],
        foyer: [
          { value: 'NF EN 13229', text: 'NF EN 13229' },
        ],
        cuisiniere: [
          { value: 'NF EN 12815', text: 'NF EN 12815' },
        ],
      },
      chauffeBoisData: null,
      chauffeBoisExist: false,
    };
  },
  created() {
    if (this.getdevisMonoFiche && this.getdevisMonoFiche.data) {
      this.chauffeBoisData = this.initializechauffeBois();
      this.setSelectedValues();
    }
  },
  
  computed: {
    ...mapGetters(["getdevisMonoFiche"]),
    normeOptions() {
      return this.type ? this.normesByType[this.type] : [];
    },
    buttonText() {
      return this.visibleTabsLength <= 1 ? this.$t("NEXT") : this.$t("CONFIRMER");
    },
  },
 watch: {
    getdevisMonoFiche: {
      handler(newDevis) {
        if (newDevis && newDevis.data) {
          this.chauffeBoisData = this.initializechauffeBois();
          this.chauffeBoisExist = newDevis.data?.devis.chauffage_au_bois !== null;
          this.type = newDevis.data?.devis?.chauffage_au_bois?.type || null;
          this.normes = newDevis.data?.devis?.chauffage_au_bois?.normes || null;
          this.setSelectedValues();
          if (this.chauffeBoisExist) {
            this.isCompleted = true;
          }
        }
      },
      deep: true,
      immediate: true,
    },
    'chauffeBoisData.bois_granule': {
    handler(newValue) {
      this.selectedBoisGranule = newValue ? 'bois_granules' : 'bois_autre_granules';
    },
    immediate: true,
  },
 
  },
  methods: {
    ...mapActions([
      "setDevisMonoFiche",
      "editchauffeBois",
      "storechauffeBois",
    ]),
    onKeyDown(event) {
      preventExceedingLengthFloat(event);
    },
    
    onPaste(event, fieldName) {
      handlePaste.call(this, event, fieldName);
    },
    setSelectedValues() {
    
      if (!this.chauffeBoisData.flammeverte ) {
        this.selected = "B";
      } else {
        this.selected = "A";
      }
    },
    initializechauffeBois() {
     
      const chauffeBois =this.getdevisMonoFiche?.data?.devis.chauffage_au_bois || {};
      
      this.selectedBoisGranule = chauffeBois.bois_granule ? 'bois_granules' : 'bois_autre_granules';
 
      return {
        id: chauffeBois.id,
        flammeverte: chauffeBois.flammeverte || false,
        bois_granule: chauffeBois.bois_granule || false,
          rendement_nominal: chauffeBois.rendement_nominal || "",
          emissions_particules: chauffeBois.emissions_particules || "",
          emissions_CO: chauffeBois.emissions_CO || "",
          emissions_NOx: chauffeBois.emissions_NOx || "",
          type: chauffeBois.type || "",
          normes:chauffeBois.normes||"",
        };
    },
    handleBoisGranuleChange(selectedValue) {
    
    this.chauffeBoisData.bois_granule = selectedValue === 'bois_granules';
  },
    async chauffeBoisAjouter() {
      if (this.selected === "B") {
        this.chauffeBoisData.flammeverte = false;
      } else {
        this.chauffeBoisData.flammeverte = true;
      }
      this.chauffeBoisData.type=this.type;
      this.chauffeBoisData.normes=this.normes;

      const response = await this.storechauffeBois(this.chauffeBoisData);
    
      this.getdevisMonoFiche.data.devis.chauffage_au_bois_id =
        response.data.id;
      this.getdevisMonoFiche.data.devis.chauffage_au_bois = {
        id: response.data.id,
        bois_granule: this.chauffeBoisData.bois_granule,
        rendement_nominal: this.chauffeBoisData.rendement_nominal,
        emissions_particules: this.chauffeBoisData.emissions_particules,
        flammeverte: this.chauffeBoisData.flammeverte,
        emissions_CO:this.chauffeBoisData.emissions_CO,
        emissions_NOx: this.chauffeBoisData.emissions_NOx,
        normes: this.chauffeBoisData.normes,
        type:this.chauffeBoisData.type,
      };
      this.setDevisMonoFiche(this.getdevisMonoFiche);

    },

    async chauffeBoisAdd() {
 
      if (this.chauffeBoisExist) {
        if (this.selected === "B") {
          this.chauffeBoisData.flammeverte = false;
        } else {
          this.chauffeBoisData.flammeverte = true;
          this.chauffeBoisData.emissions_CO=0;
          this.chauffeBoisData.emissions_NOx=0;
          this.chauffeBoisData.emissions_particules=0
          this.chauffeBoisData.rendement_nominal=0;
       
        }
        this.chauffeBoisData.type=this.type;
        this.chauffeBoisData.normes=this.normes;
        const chauffe = {
          ...this.chauffeBoisData,
          id: this.getdevisMonoFiche.data.devis.chauffage_au_bois.id,
        };

        const reponse = await this.editchauffeBois(chauffe);
        this.getdevisMonoFiche.data.devis.chauffage_au_bois = {
          id: this.getdevisMonoFiche.data.devis.chauffage_au_bois.id,
          bois_granule: this.chauffeBoisData.bois_granule,
          rendement_nominal: this.chauffeBoisData.rendement_nominal,
            emissions_particules: this.chauffeBoisData.emissions_particules,
            flammeverte: this.chauffeBoisData.flammeverte,
            emissions_CO: this.chauffeBoisData.emissions_CO,
            emissions_NOx: this.chauffeBoisData.emissions_NOx,
            normes: this.chauffeBoisData.normes,
            type: this.chauffeBoisData.type,
          };
        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return reponse;
      }

       await this.chauffeBoisAjouter();
       this.setDevisMonoFiche(this.getdevisMonoFiche);
    },
    async goToStep() {
      this.isLoading=true;
      await this.chauffeBoisAdd();
      this.isLoading=false;
      this.isCompleted = true;
      if(this.visibleTabsLength<=1){
        this.$emit("change",true);
      }else {
        this.$emit("change",false);
      }
    },
    async goToNextStep() {
      this.isLoading=true;
      await this.chauffeBoisAdd();
      this.isLoading=false;
      this.$refs.snackbar.showSnackbar("Tous_obligatoires");
        this.isCompleted = false;
        return;
    },
    goToPrevStep() {
      if(this.visibleTabsLength<=1){
        this.$emit("changePrev",true);
      }else {
        this.$emit("changePrev",false);
      }
      this.$emit("changeStep", 4);
    },
  },
};
</script>
<style scoped>
.custom-placeholder::placeholder {
  font-size: 13px;
}
.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-input {
  box-shadow: none;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>
